@import "./../../../assets/styles/helpers/variables.scss";
.bc {
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $bc-secondary-color;
        background: -o-linear-gradient(top, $bc-secondary-color 0%, $bc-secondary-color 100%);
        background: -ms-linear-gradient(top, $bc-secondary-color 0%, $bc-secondary-color 100%);
        background: -webkit-linear-gradient($bc-secondary-color, $bc-secondary-color);
        background: linear-gradient($bc-secondary-color, $bc-secondary-color);
        max-width: 300px;
        margin: 0 auto;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
    }
}

.midc, .os {
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $midc-secondary-color;
        background: -o-linear-gradient(top, $midc-secondary-color 0%, $midc-secondary-color 100%);
        background: -ms-linear-gradient(top, $midc-secondary-color 0%, $midc-secondary-color 100%);
        background: -webkit-linear-gradient($midc-secondary-color, $midc-secondary-color);
        background: linear-gradient($midc-secondary-color, $midc-secondary-color);
        border-color: $midc-secondary-color;
        border-bottom-color: $midc-secondary-color;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
    }
}

.form-control {
    display: block !important;
    width: 100% !important;
    height: inherit !important;
    padding: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    background-color: inherit !important;
    background-clip: padding-box !important;
    border: initial !important;
    border-radius: inherit !important;
    transition: none !important;
}