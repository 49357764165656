
.arrow{
    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  
  .mdc-icon-asesor {
      width: 70px;
  }