@import "assets/styles/helpers/variables.scss";
@import "assets/styles/helpers/typography.scss";
@import "assets/styles/components/icons.scss";
@import "assets/styles/base/forms.scss";

html, body { height: 100%; }

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.grecaptcha-badge {
    display: none !important;
}

/* General Custom */
body{
    font-family: $roboto !important;
    margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
}

.h {
    min-height: 60vh;
}

.politicas {
    background-color: transparent;
    color: $txt-gray;
    font-size: 0.5em;
    p {
        color: $txt-gray;
    }
    a {
        color: $txt-gray;
    }
    @media (min-width: 1024px){
        font-size: 0.7em;
    }
}

.content {
    background-image: url(/assets/images/backgrounds/bkground1.svg);
    background-color: $white;
    background-size: cover;
    background-position: 100% 100%;
    background-blend-mode:multiply;
    -webkit-background-size: cover;   
}

.mat-form-field-flex {
    background: $white;
}

.icon-asesor {
    width: 70px;
}

.bc {

    header {
        .logo {
            max-width: 300px !important;
        }
    }
    
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $bc-secondary-color;
        background: -o-linear-gradient(top, $bc-secondary-color 0%, $bc-secondary-color 100%);
        background: -ms-linear-gradient(top, $bc-secondary-color 0%, $bc-secondary-color 100%);
        background: -webkit-linear-gradient($bc-secondary-color, $bc-secondary-color);
        background: linear-gradient($bc-secondary-color, $bc-secondary-color);
        max-width: 300px;
        margin: 0 auto;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
        &.btn-lg{
            width: 100%;
        }
        &.disabled{
            opacity: 0.6;
            background: $txt-gray;
            color: $white;
        }
    }
    .modal-confirm{
        .modal-content{
            background-image: url(/assets/images/backgrounds/pop_ok_bc.svg);
        }
    }
    .arrow {
        border: solid $bc-secondary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        @media (min-width: 768px){
            padding: 5px;
        }
    }
    .back {
        color: $bc-secondary-color;
        margin-left: 3px;
        @media (min-width: 768px){
            margin-left: 3px;
        }
    }
    .back-cont {
        color: $bc-secondary-color;
        border-width: 1px;
        border: solid;
        border-color: $bc-secondary-color;
        width: 90px;
        height: 40px;
        overflow: hidden;
        @media (min-width: 768px){
			width: auto;
            height: 45px;
            overflow: hidden;
		}
    }
    .title {
        color: $bc-secondary-color;
        font-family: $roboto;
        font-weight: $light;
        font-size: 2em;
        line-height: 1em;
        @media (min-width: 768px){
            font-size: 3em;
            line-height: 1.2em;
        }
    }
    .title:hover{
        color: $bc-secondary-color;
    }
    h6 {
        color: $bc-secondary-color;
        font-weight: $bold;
    }
    .cont {
        color: $bc-secondary-color;
        border-radius: 5px;
        a {
            color: $bc-secondary-color;
            font-weight: $bold;
        }
    }
    .footer{
        border-top: 2px solid $bc-secondary-color;
    }
    .brand {
        background-color: $bc-secondary-color;
    }   
    .link{
        color: $bc-secondary-color;
        cursor: pointer;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: $bc-secondary-color;
    }
    hr {
        background-color: $bc-secondary-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0 auto !important;
    }
}
.midc, .os {

    header {
        .logo {
            max-width: 200px !important;
        }
    }
    .clear {
        clear: both;
    }
    
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $midc-secondary-color;
        background: -o-linear-gradient(top, $midc-secondary-color 0%, $midc-secondary-color 100%);
        background: -ms-linear-gradient(top, $midc-secondary-color 0%, $midc-secondary-color 100%);
        background: -webkit-linear-gradient($midc-secondary-color, $midc-secondary-color);
        background: linear-gradient($midc-secondary-color, $midc-secondary-color);
        border-color: $midc-secondary-color;
        border-bottom-color: $midc-secondary-color;
        border: none !important;
        max-width: 300px;
        margin: 0 auto;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
        &.btn-lg{
            width: 100%;
        }
        &.disabled{
            opacity: 0.6;
            background: $txt-gray;
            color: $white;
        }
    }
    .arrow {
        border: solid $midc-secondary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        @media (min-width: 768px){
            padding: 5px;
        }
    }
    .back {
        color: $midc-secondary-color;
        border-width: 1px;
        margin-left: 3px;
        @media (min-width: 768px){
            margin-left: 3px;
        }
    }
    .back-cont {
        color: $midc-secondary-color;
        border-width: 1px;
        border: solid;
        border-color: $midc-secondary-color;
        width: 90px;
        height: 40px;
        overflow: hidden;
        @media (min-width: 768px){
			width: auto;
            height: 45px;
            overflow: hidden;
		}
    }
    .title {
        color: $midc-secondary-color;
        font-family: $roboto;
        font-weight: $light;
        font-size: 2em;
        line-height: 1em;
        @media (min-width: 768px){
            font-size: 3em;
            line-height: 1.2em;
        }
    }
    .title:hover{
        color: $midc-secondary-color;
    }
    h6 {
        color: $midc-secondary-color;
        font-weight: $bold;
    }
    .cont {
        color: $midc-secondary-color;
        border-radius: 5px;
        a {
            color: $midc-secondary-color;
            font-weight: $bold;
        }
    }
    .footer{
        border-top: 2px solid $midc-secondary-color;
    }
    .brand {
        background-color: $midc-secondary-color;
    }   
    .link{
        color: $midc-secondary-color;
        cursor: pointer;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: $midc-secondary-color;
    }
    .mdc-hr {
        background-color: $midc-secondary-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
    }
}
.vrf {

    header {
        .logo {
            max-width: 300px !important;
        }
    }
    
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $vrf-button;
        background: -o-linear-gradient(top, $vrf-button 0%, $vrf-button 100%);
        background: -ms-linear-gradient(top, $vrf-button 0%, $vrf-button 100%);
        background: -webkit-linear-gradient($vrf-button, $vrf-button);
        background: linear-gradient($vrf-button, $vrf-button);
        max-width: 300px;
        margin: 0 auto;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
        &.btn-lg{
            width: 100%;
        }
        &.disabled{
            opacity: 0.6;
            background: $txt-gray;
            color: $white;
        }
    }
    .modal-confirm{
        .modal-content{
            background-image: url(/assets/images/backgrounds/pop_ok_bc.svg);
        }
    }
    .arrow {
        border: solid $vrf-button;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        @media (min-width: 768px){
            padding: 5px;
        }
    }
    .back {
        color: $vrf-button;
        margin-left: 3px;
        @media (min-width: 768px){
            margin-left: 3px;
        }
    }
    .back-cont {
        display: none;
        color: $vrf-button;
        border-width: 1px;
        border: solid;
        border-color: $vrf-button;
        width: 90px;
        height: 40px;
        overflow: hidden;
        @media (min-width: 768px){
			width: auto;
            height: 45px;
            overflow: hidden;
		}
    }
    .title {
        color: $vrf-button;
        font-family: $roboto;
        font-weight: $light;
        font-size: 2em;
        line-height: 1em;
        @media (min-width: 768px){
            font-size: 3em;
            line-height: 1.2em;
        }
    }
    .title:hover{
        color: $vrf-button;
    }
    h6 {
        color: $vrf-button;
        font-weight: $bold;
    }
    .cont {
        color: $vrf-button;
        border-radius: 5px;
        a {
            color: $vrf-button;
            font-weight: $bold;
        }
    }
    .footer{
        border-top: 2px solid $vrf-button;
    }
    .brand {
        background-color: $vrf-secundary-color;
    }   
    .link{
        color: $bc-secondary-color;
        cursor: pointer;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: $vrf-secundary-color;
    }
    hr {
        background-color: $vrf-secundary-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0 auto !important;
    }
}
.pntd{

    header {
        .logo {
            max-width: 300px !important;
        }
    }
    
    .btn.btn-primary {
        font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $pntd-secundary-color;
        background: -o-linear-gradient(top, $pntd-secundary-color 0%, $pntd-secundary-color 100%);
        background: -ms-linear-gradient(top, $pntd-secundary-color 0%, $pntd-secundary-color 100%);
        background: -webkit-linear-gradient($pntd-secundary-color, $pntd-secundary-color);
        background: linear-gradient($pntd-secundary-color, $pntd-secundary-color);
        border-color: $pntd-secundary-color;
        border-bottom-color: $pntd-secundary-color;
        border: none !important;
        max-width: 300px;
        margin: 0 auto;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
        &.btn-lg{
            width: 100%;
        }
        &.disabled{
            opacity: 0.6;
            background: $txt-gray;
            color: $white;
        }
    }
    .arrow {
        border: solid $pntd-secundary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        @media (min-width: 768px){
            padding: 5px;
        }
    }
    .back {
        color: $pntd-secundary-color;
        border-width: 1px;
        margin-left: 3px;
        @media (min-width: 768px){
            margin-left: 3px;
        }
    }
    .back-cont {
        color: $pntd-secundary-color;
        border-width: 1px;
        border: solid;
        border-color: $pntd-secundary-color;
        width: 90px;
        height: 40px;
        overflow: hidden;
        @media (min-width: 768px){
			width: auto;
            height: 45px;
            overflow: hidden;
		}
    }
    .title {
        color: $pntd-title;
        font-family: $roboto;
        font-weight: $light;
        font-size: 2em;
        line-height: 1em;
        @media (min-width: 768px){
            font-size: 3em;
            line-height: 1.2em;
        }
    }
    .title:hover{
        color: $pntd-title;
    }
    h6 {
        color: $pntd-secundary-color;
        font-weight: $bold;
    }
    .cont {
        color: $pntd-secundary-color;
        border-radius: 5px;
        a {
            color: $pntd-secundary-color;
            font-weight: $bold;
        }
    }
    .footer{
        border-top: 2px solid $pntd-title;
        .title{
            color: $pntd-secundary-color;
        }
    }
    .brand {
        background-color: $pntd-title;
    }   
    .link{
        color: $pntd-secundary-color;
        cursor: pointer;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: $pntd-secundary-color;
    }
    hr {
        background-color: $pntd-secundary-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0 auto !important;
    }
}
.btn-error{
    font-family: $roboto !important;
        font-weight: $bold;
        color: $white;
        background-color: $error-color;
        background: -o-linear-gradient(top, $error-color 0%, $error-color 100%);
        background: -ms-linear-gradient(top, $error-color 0%, $error-color 100%);
        background: -webkit-linear-gradient($error-color, $error-color);
        background: linear-gradient($error-color, $error-color);
        border-color: $error-color;
        border-bottom-color: $error-color;
        border: none !important;
        i {
            border: solid $white;
            border-width: 0 2px 2px 0;
        }
        .arrow{
            border: solid $white;
            border-width: 0 2px 2px 0;
        }

}

.help{
    font-family: $roboto;
	.ico{
		width: 100%;
		max-width: 100px;
		margin: auto;
	}
	span{
		color: $dark-gray;
        font-size: 11px;
        line-height: 15px;
        display: block;
	}
	a{
		text-decoration: underline;
		color: $dark-gray;
	}
}
button{
    &.button-recovery{
        border: 1px solid #632678 !important;
        background-color: #DFACF1 !important;
        color: #6338A1 !important;
        text-decoration: none !important;
        font-weight: 600 !important;
    }
}