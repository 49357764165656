/* VARIABLES */
$bg-dark: #575756;
$bg-light: hsla(240, 27%, 96%, 1);
$bg-mid: hsla(250, 10%, 88%, 1);
$dark-gray: #575756;
$light-gray: hsla(0, 0%, 80%, 1);
$txt-gray: hsla(0, 0%, 50%, 1);
$white: #FFFFFF;
$black: hsla(0, 0%, 0%, 1);

/* Busca Crédito */
$bc-secondary-color: #26478d;
$bc-bright-blue: #406eb3;

/* Mi Datacrédito */
$midc-secondary-color: #632678;
$os-secondary-color: #632678;

/*data Verifica*/
$vrf-secundary-color: #632678;
$vrf-button: #BA2F7D;
/*Ponte al dia*/
$pntd-secundary-color:#B12384;
$pntd-title:#08478E;
/*error*/
$error-color: #982881;

/********* ONESHOT *********/
$gray: #F0F0F0;
$blackOS: #000000;
$grayDark: #424448;
$purple: #632678;
$grayMedium: #999999;
$yellow: #E2A235;
$blue: #26478D;
$pink: #BB0048;